import React from 'react';
import { withTranslation } from 'react-i18next';
import ExtensibleCard from '../../components/ExtensibleCard/ExtensibleCard';

class Risks extends React.Component {
  render () {
    const { t } = this.props;
    return (
      <div>
        <div>
          <h3>{t("risks")}</h3>
        </div>
        <div className="grid--1">
          <ExtensibleCard title={t("risks5Short")} content={t("risks5Long")} content2={t("risks5Values")} />
          <ExtensibleCard title={t("risks6Short")} content={t("risks6Long")} content2={t("risks6Values")} />
          <ExtensibleCard title={t("risks7Short")} content={t("risks7Long")} content2={t("risks7Values")} />
          <ExtensibleCard title={t("risks8Short")} content={t("risks8Long")} content2={t("risks8Values")} />
          <ExtensibleCard title={t("risks9Short")} content={t("risks9Long")} content2={t("risks9Values")} />
          <ExtensibleCard title={t("risks4Short")} content={t("risks4Long")} content2={t("risks4Values")} />

          <ExtensibleCard title={t("risks1Short")} content={t("risks1Long")} content2={t("risks1Values")} />
          <ExtensibleCard title={t("risks2Short")} content={t("risks2Long")} content2={t("risks2Values")} />
          <ExtensibleCard title={t("risks3Short")} content={t("risks3Long")} content2={t("risks3Values")} />
        </div>
      </div>
    )
  }
}

export default withTranslation()(Risks)