import React from 'react';
import { withTranslation } from 'react-i18next';

import video from './BLU-EVENT.webm';
import img1 from './1.webp';
import img2 from './2.webp';
import img3 from './3.webp';
import './ProjectDescription.sass';

class ProjectDescription extends React.Component {
  #videoRef = React.createRef();

  componentDidMount() {
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.2
    };

    let observer = new IntersectionObserver(this.#runVideoWhenVisible, options);
    observer.observe(this.#videoRef.current);
  }

  render() {
    const { t } = this.props;
    return (
      <div>
        <div>
          <h3>{t("infoAbout")} BluHolding</h3>
        </div>
        <div>
          <p>{t("content1par2")}</p>
          <p>{t("content1par3")}</p>
          <p>{t("content1par4")}</p>
          <p>{t("content1par5")}</p>
        </div>
        <div className="overflow-container">
          <h3>{t("inNumbers")}</h3>
          {/*<table className="w100">
            <thead>
              <tr>
                <th>BluHolding</th>
                <th>I 2022</th>
                <th>II 2022</th>
                <th>III 2022</th>
                <th>IV 2022</th>
                <th>I 2023</th>
                <th>II 2023</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Liczba klientów</td>
                <td>1325</td>
                <td>1219</td>
                <td>952</td>
                <td>876</td>
                <td>1030</td>
                <td>1568</td>
              </tr>
              <tr>
                <td>Wzrost pracowników</td>
                <td>35</td>
                <td>38</td>
                <td>54</td>
                <td>55</td>
                <td>69</td>
                <td>61</td>
              </tr>
              <tr>
                <td>Przychód PLN</td>
                <td>974 238,92</td>
                <td>2 263 851,91</td>
                <td>3 390 892,59</td>
                <td>5 372 597,26</td>
                <td>1 903 006,41</td>
                <td>3 638 813,18</td>
              </tr>
            </tbody>
    </table>*/}
        </div>
        <div className="charts">
          <img src={img1} alt="Przyrost klientów" className="w100" />
          <img src={img2} alt="Przyrost pracowników" className="w100" />
          <img src={img3} alt="Przychód" className="w100" />
          <video ref={this.#videoRef} muted={true} className="w100" src={video} controls={true}></video>
        </div>
      </div>
    );
  }

  #runVideoWhenVisible = (entries, observer) => {
    entries.forEach(entry => {
      if (1) {
        if (entry.isIntersecting) {
          entry.target.play();
        }
        else {
          entry.target.pause();
        }
      }
    });
  }
}

export default withTranslation()(ProjectDescription);