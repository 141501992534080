import React from "react";
import { withTranslation } from "react-i18next";
import "./OurInvestments.sass";
import InvestCard from "../../components/InvestCard/InvestCard";
import Persons from "./../Home/persons.png";
import Technology from "./technology.png";
import Cybersec from "./cybersec.png";
import Growth from "./growth.png";
import CyberGlowa from './cyberglowa.png';


class OurInvestments extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <h3>{t("ourInvestments")}</h3>
        <div className="grid--2-1 OurInvestmentsWrapper">
          <InvestCard
            image={Persons}
            alt="Tekst alternatywny"
            title={t("invest1Title")}
            value="25000 PLN"
            content={t("invest1Content")}
          />
          <InvestCard
            image={Technology}
            alt="Tekst alternatywny"
            title={t("invest2Title")}
            value="25000 PLN"
            content={t("invest2Content")}
          />
          <InvestCard
            image={Cybersec}
            alt="Tekst alternatywny"
            title={t("invest3Title")}
            value="25000 PLN"
            content={t("invest3Content")}
          />
          <InvestCard
            image={Growth}
            alt="Tekst alternatywny"
            title={t("invest4Title")}
            value="25000 PLN"
            content={t("invest4Content")}
          />
          <InvestCard
            image={CyberGlowa}
            alt="Tekst alternatywny"
            title={t("invest5Title")}
            value="25000 PLN"
            content={t("invest5Content")}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(OurInvestments);
