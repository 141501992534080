import React from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import './Discuss.sass'
import Comment from '../../components/Comment/Comment';
import ExtensibleCard from '../../components/ExtensibleCard/ExtensibleCard';

class Discuss extends React.Component {
  render () {
    const { t } = this.props;
    return (
      <div className="Discuss">
        <div>
          <h3>{t("discuss")}</h3>
        </div>
        <div className="grid--1">
          <ExtensibleCard title={t("faq1title")} content={t("faq1content1")} content2={t("faq1content2")} />
          <ExtensibleCard title={t("faq2title")} content={t("faq2content1")} content2={t("faq2content2")} />
          <ExtensibleCard title={t("faq3title")} content={t("faq3content1")} content2={t("faq3content2")} />
          <ExtensibleCard title={t("faq4title")} content={t("faq4content1")} content2={t("faq4content2")} />
          <ExtensibleCard title={t("faq5title")} content={t("faq5content1")} content2={t("faq5content2")} />
          <ExtensibleCard title={t("faq6title")} content={t("faq6content1")} content2={t("faq6content2")} />
          <ExtensibleCard title={t("faq7title")} content={t("faq7content1")} content2={t("faq7content2")} />
          <ExtensibleCard title={t("faq8title")} content={t("faq8content1")} content2={t("faq8content2")} />
          <ExtensibleCard title={t("faq9title")} content={t("faq9content1")} content2={t("faq9content2")} />
          <ExtensibleCard title={t("faq10title")} content={t("faq10content1")} content2={t("faq10content2")} />
          <ExtensibleCard title={t("faq11title")} content={t("faq11content1")} content2={t("faq11content2")} />
          <ExtensibleCard title={t("faq12title")} content={t("faq12content1")} content2={t("faq12content2")} />
          <ExtensibleCard title={t("faq13title")} content={t("faq13content1")} content2={t("faq13content2")} />
          <ExtensibleCard title={t("faq14title")} content={t("faq14content1")} content2={t("faq14content2")} />
          <ExtensibleCard title={t("faq15title")} content={t("faq15content1")} content2={t("faq15content2")} />
          <ExtensibleCard title={t("faq16title")} content={t("faq16content1")} content2={t("faq16content2")}> 
            <ul>
              <li>Crowdfunding: <a href="https://www.knf.gov.pl/dla_rynku/crowdfunding">LINK</a></li>
              <li>{t("forInvestors")}: <a href="https://www.knf.gov.pl/dla_rynku/crowdfunding/inwestorzy">LINK</a></li>
              <li>{t("forEmitters")}: <a href="https://www.knf.gov.pl/dla_rynku/crowdfunding/emitenci">LINK</a></li>
            </ul>
          </ExtensibleCard>
          <ExtensibleCard title={t("faq17title")} content={t("faq17content1")} content2={t("faq17content2")} />
        </div>
        <br />
        <p><b>{t("youDontFound")}</b></p>
        <br />
        <textarea placeholder={t("startWriting")}></textarea>
        <br /><br />
        <div className="flex justify-between">
          <p><Link to="/login">{t("logIn")}</Link></p>
          <input type="button" value={t("sendComment")} className="button--primary" />
        </div>
        {/* <div>
          <Comment
            profileName="Ilona Masłowska"
            comment="Cras a nunc facilisis, viverra mi eu, varius turpis. Vestibulum ut molestie ipsum, a tincidunt nibh. Suspendisse imperdiet sodales mauris, vitae posuere nulla semper ac. Integer auctor lectus pulvinar quam consectetur eleifend."
            date="2023-07-19"
          />
          <Comment
            profileName="Stanisław Zamoyski"
            comment="Cras a nunc facilisis, viverra mi eu, varius turpis. Vestibulum ut molestie ipsum, a tincidunt nibh. Suspendisse imperdiet sodales mauris, vitae posuere nulla semper ac. Integer auctor lectus pulvinar quam consectetur eleifend."
            date="2023-07-18T15:25:22"
          />
          <Comment
            profileName="Jan Kowalski"
            comment="Cras a nunc facilisis, viverra mi eu, varius turpis. Vestibulum ut molestie ipsum, a tincidunt nibh. Suspendisse imperdiet sodales mauris, vitae posuere nulla semper ac. Integer auctor lectus pulvinar quam consectetur eleifend."
            date="2023-07-10T20:30:00"
          />
        </div> */}
      </div>
    )
  }
}

export default withTranslation()(Discuss)