import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next"
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Bluholding from './bluholding.webp';
import { Link } from "react-router-dom";

const Error404 = () => {
  const { t } = useTranslation();

  return (
    <div className="flex justify-center align-center h100">
      <div className="text-center">
        <img src={Bluholding} alt="Bluholding" style={{filter: "invert()"}}/>
        <h1>{t("pageNotFound")}</h1>
        <Link to="/">
          <input type="button" value={t("goToMain")} className="button--primary" />
        </Link>
      </div>
    </div>
  )
}

export default Error404