import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./views/Home/Home";
import History from "./views/History/History";
import i18n from "./i18n";
import { I18nextProvider } from "react-i18next";
import Voucher from "./views/Voucher/Voucher";
import ScrollToTop from "./scrollToTop";
import Error404 from "./views/Error404/Error404";
import TagManager from "react-gtm-module";

const root = ReactDOM.createRoot(document.getElementById("root"));

const tagManagerArgs = {
  gtmId: "GTM-PR8S6CVR",
};
TagManager.initialize(tagManagerArgs);

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/nasza-historia" element={<History />} />
          <Route path="/voucher" element={<Voucher />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function/s
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
