import React from "react";
import { withTranslation } from "react-i18next";
import "./InvestCard.sass";

class InvestCard extends React.Component {
  #image;
  #alt;
  #title;
  #value;
  #content;

  constructor(props) {
    super(props);
    this.#image = props.image;
    this.#alt = props.alt;
    this.#title = props.title;
    this.#value = props.value;
    this.#content = props.content.split(" | ");
  }

  render() {
    const { t } = this.props;
    return (
      <div className="InvestCard">
        <div className="InvestCard__header">
          <img
            className="InvestCard__image"
            src={this.#image}
            alt={this.#alt}
            width={70}
          />
          <div>
            <h4>{this.#title}</h4>
            {/* <p className="InvestCard__value">
              {t("value")}: {this.#value}
            </p> */}
          </div>
        </div>
        <ul className="InvestCard__content">
          {this.#content.map((item, i) => {
            return <li key={i}>{item}</li>;
          })}
        </ul>

        {/* <p className="InvestCard__content">{this.#content}</p> */}
      </div>
    );
  }
}

export default withTranslation()(InvestCard);
