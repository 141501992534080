import React from 'react';
import { withTranslation } from 'react-i18next';
import InvestPackage from '../../components/InvestPackage/InvestPackage';

class InvestPackages extends React.Component {
  render () {
    const { t } = this.props;
    return (
      <div>
        <div>
          <h3>{t("investPackage")}</h3>
        </div>
        <div className="grid--custom-Invest">
          <InvestPackage
            className="item-1"
            title={`${t("from")} 2 ${t("shares")}`} // 510 PLN
            desc="Soft Blu"
            listAsArray={
              [
                t("fbgroup"),
              ]
            }
          />
          <InvestPackage
            className="item-2"
            title={`${t("from")} 20 ${t("shares")}`} // 5 100 PLN
            desc="Light Blu"
            listAsArray={
              [
                t("letterOfThanks"),
                t("fbgroup"),
              ]
            }
          />
          <InvestPackage
            className="item-3"
            title={`${t("from")} 100 ${t("shares")}`} // 25 500 PLN
            desc="Medium Blu"
            listAsArray={
              [
                t("vipSet"),
                t("fbgroup"),
                t("letterOfThanks"),
              ]
            }
          />
          <InvestPackage
            className="item-4"
            title={`${t("from")} 200 ${t("shares")}`} // 51 000 PLN
            desc="Dark Blu"
            listAsArray={
              [
                t("epulpitFree5"),
                t("ppvAccess"),
                t("vipSet"),
                t("fbgroup"),
              ]
            }
          />
          <InvestPackage
            className="item-5"
            title={`${t("from")} 500 ${t("shares")}`} // 127 500 PLN
            desc="Deep Blu"
            listAsArray={
              [
                t("exclusiveMeeting"),
                t("investorStatue"),
                t("securityAudit"),
                t("trainings"),
                t("epulpitFree5"),
                t("ppvAccess"),
                t("vipSet"),
                t("fbgroup"),
              ]
            }
          />
        </div>
      </div>
    )
  }
}

export default withTranslation()(InvestPackages)