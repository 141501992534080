import React from "react";
import { withTranslation } from "react-i18next";
import File from "../../components/File/File";
import "./Document.sass";

class Documents extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className="Documents">
        <div>
          <h3>{t("documents")}</h3>
        </div>
        <div className="grid--2-1 Documents__grid">
          <File
            active={true}
            fileLink="/static/pdf/PolitykaPrywatności.pdf"
            fileName={t("docPrivacyPolicy")}
          />
          {/* <File active={false} fileLink="t" fileName={t("docInformation")} /> */}
          <File
            active={true}
            fileLink="/static/pdf/BGS  patent zaświadczenie.pdf"
            fileName={t("docPatent")}
          />
          {/* <File active={false} fileLink="t" fileName={t("docAnnouncement")} />
          <File active={false} fileLink="t" fileName={t("docRZIS")} />
          <File active={false} fileLink="t" fileName={t("docRODO")} />
          <File active={false} fileLink="t" fileName={t("docValuation")} /> */}
          <File
            active={true}
            fileLink="/static/pdf/Odpis_Pełny_KRS_0001018668.pdf"
            fileName={t("docKRS")}
          />
          {/* <File active={false} fileLink="t" fileName={t("docCashFlows")} />
          <File active={false} fileLink="t" fileName={t("docBalance")} /> */}
        </div>
      </div>
    );
  }
}

export default withTranslation()(Documents);
