import React from 'react';
import { withTranslation } from 'react-i18next';
import './InvestPackage.sass'

class InvestPackage extends React.Component {
  #title;
  #desc;
  #listAsArray;
  #className;

  constructor (props) {
    super(props);
    this.#title = props.title;
    this.#desc = props.desc;
    this.#listAsArray = props.listAsArray;
    this.#className = props.className;
  }

  render () {
    const { t } = this.props;
    return (
      <div className={`InvestPackage ${this.#className}`}>
        <p className="InvestPackage__name"><b>{t(this.#desc)}</b></p>
        <p className="InvestPackage__title">{t(this.#title)}</p>
        <ul>
          {
            this.#listAsArray.map((item, index) => {
              return <li key={index}>{t(item)}</li>
            })
          }
        </ul>
        <div className="InvestPackage__color-bar"></div>
      </div>
    )
  }
}
export default withTranslation()(InvestPackage)