import { t } from 'i18next';
import React from 'react';
import i18n from "i18next"
import { withTranslation } from 'react-i18next';
import './Investment.sass'
import FoundGray from './found-gray.png';
import Found from './founds.png';

class Investment extends React.Component {
  #name;
  #grayedOut;
  #value;
  #date;
  #icon;

  constructor (props) {
    super(props);
    this.#name = props.name || t("nameHidden")
    this.#icon = props.icon || Found;
    this.#value = props.value;
    this.#date = props.date;


    if (this.#grayedOut) this.#icon = FoundGray;
  }

  render () {
    const { t } = this.props
    return (
      <div className={this.#grayedOut ? "Investment--gray" : "Investment"}>
        <img src={this.#icon} alt="akcjonariat" />
        <div>
          <p className="Investment__name">{this.#name}</p>
          <p className="Investment__value">{this.#value}</p>
          <p className="Investment__date">{this.#dateToLocaleString(this.#date)}</p>
        </div>
      </div>
    )
  }

  #dateToLocaleString = (dateString) => {
    // console.log(41, dateString)
    try {
      const date = new Date(dateString);
      return date.toLocaleString([i18n.language], {day: "2-digit", month: "long", year: "numeric"})
    } catch (err) {
      console.error(err)
      return;
    }
  }
}

export default withTranslation()(Investment)