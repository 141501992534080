"use client";

import { useState } from "react";
import styles from "./Voucher.module.scss";
import Swal from "sweetalert2";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useTranslation } from "react-i18next";

// export const metadata = {
//   title: "BluHolding | Voucher",
//   description:
//     "Wdrażamy nowoczesne rozwiązania gwarantujące najwyższy poziom bezpieczeństwa danych oraz zapewniamy podniesienie zysków dzięki znacznemu obniżeniu kosztów.",
// };

export default function Home() {
  const { t, i18n } = useTranslation();
  const packagesData = [
    {
      title: "Pakiet I",
      options: ["Backup", t("vpsserver"), "Hosting", t("SSLcertificate")],
    },
    {
      title: "Pakiet II",
      options: [t("dataSpace")],
    },
    {
      title: "Pakiet III",
      options: [t("performanceAudit")],
    },
    {
      title: "Pakiet IV",
      options: [t("article"), t("banner")],
    },
    {
      title: "Pakiet V",
      options: [t("logo")],
    },
  ];

  const [voucherCode, setVoucherCode] = useState("");
  const [popupClose, setPopupClose] = useState(false);
  const [packagesDisable, setPackagesDisable] = useState(false);

  const checkVoucher = () => {
    fetch("http://46.238.215.8/api/checkVoucher", {
      method: "POST",
      header: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        code: voucherCode,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === "success") {
          Swal.fire({
            icon: "success",
            text: res.msg,
            toast: true,
            timer: 3500,
            position: "top",
            showConfirmButton: false,
          });

          setPopupClose(true);
        } else {
          Swal.fire({
            icon: "error",
            text: res.msg,
            toast: true,
            timer: 3500,
            position: "top",
            showConfirmButton: false,
          });
        }
      });
  };

  const choosePackage = (title) => {
    Swal.fire({
      title: `Czy na pewno chcesz wybrać ${title}?`,
      showDenyButton: true,
      confirmButtonText: "Tak",
      confirmButtonColor: "#444",
      denyButtonText: "Nie",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "info",
          text: "Wysyłanie...",
          toast: true,
          position: "top",
          showConfirmButton: false,
        });

        setPackagesDisable(true);

        fetch("http://46.238.215.8/api/useVoucher", {
          method: "POST",
          header: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            code: voucherCode,
            package: title,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.status === "success") {
              Swal.fire({
                icon: "success",
                text: res.msg,
                timer: 5000,
                showConfirmButton: false,
              });

              setTimeout(() => {
                //router.push("/");
              }, 5000);
            } else {
              Swal.fire({
                icon: "error",
                text: res.msg,
                toast: true,
                timer: 3500,
                position: "top",
                showConfirmButton: false,
              });

              setPackagesDisable(false);
            }
          });
      }
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      closePopUp();
    }
  };

  const closePopUp = () => {
    Swal.fire({
      icon: "info",
      text: "Weryfikowanie kodu...",
      toast: true,
      position: "top",
      showConfirmButton: false,
    });

    if (/^[A-Za-z]{3}\d{3}$/.test(voucherCode)) {
      checkVoucher();
    } else {
      Swal.fire({
        icon: "error",
        text: "Podano błędny kod vouchera, lub został on już wykorzystany.",
        toast: true,
        timer: 3500,
        position: "top",
        showConfirmButton: false,
      });
    }
  };

  function closeWithoutVoucher() {
    window.location.href = "/";
  }

  return (
    <div className={styles.voucher}>
      <Header />
      <div
        className={`${
          popupClose
            ? `${styles.voucher_popup} ${styles.voucher_popup_close}`
            : styles.voucher_popup
        }`}
      >
        <div className={styles.voucher_popup_container}>
          <div
            className="flex justify-center align-center"
            style={{ gap: "20px" }}
          >
            <h3 style={{ border: "none", margin: 0 }}>
              {t("giveVoucherCode")}
            </h3>
            <svg
              onClick={closeWithoutVoucher}
              style={{ cursor: "pointer" }}
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="48"
              fill="black"
              class="bi bi-x"
              viewBox="0 0 16 16"
            >
              {" "}
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />{" "}
            </svg>
          </div>
          <input
            type="text"
            placeholder="XYZ123"
            value={voucherCode}
            onChange={(e) => setVoucherCode(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <button onClick={closePopUp}>{t("goNext")}</button>
        </div>
      </div>
      <h1>{t("getVoucherBenefit")}</h1>
      <div className={styles.voucher_content}>
        {packagesData.map((item, i) => {
          return (
            <div className={styles.voucher_content_item} key={i}>
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M32 448c0 17.7 14.3 32 32 32h160V320H32v128zm256 32h160c17.7 0 32-14.3 32-32V320H288v160zm192-320h-42.1c6.2-12.1 10.1-25.5 10.1-40 0-48.5-39.5-88-88-88-41.6 0-68.5 21.3-103 68.3-34.5-47-61.4-68.3-103-68.3-48.5 0-88 39.5-88 88 0 14.5 3.8 27.9 10.1 40H32c-17.7 0-32 14.3-32 32v80c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16v-80c0-17.7-14.3-32-32-32zm-326.1 0c-22.1 0-40-17.9-40-40s17.9-40 40-40c19.9 0 34.6 3.3 86.1 80h-86.1zm206.1 0h-86.1c51.4-76.5 65.7-80 86.1-80 22.1 0 40 17.9 40 40s-17.9 40-40 40z"></path>
              </svg>
              <h3>{item.title}</h3>
              <div className={styles.voucher_content_item_bottom}>
                <ul>
                  {item.options.map((el, j) => {
                    return <li key={j}>{el}</li>;
                  })}
                </ul>
                <button
                  onClick={() => choosePackage(item.title)}
                  disabled={packagesDisable}
                >
                  {t("choose")}
                </button>
              </div>
            </div>
          );
        })}
      </div>
      <Footer />
    </div>
  );
}
