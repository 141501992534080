import React from 'react'
import { withTranslation } from 'react-i18next';
import DefaultProfileImage from './default.png';
import './Comment.sass';

class Comment extends React.Component {
  #profileName;
  #date;
  #comment;

  constructor(props) {
    super(props);
    this.#profileName = props.profileName;
    this.#date = this.#dateDiff(props.date);
    this.#comment = props.comment;
  }

  render () {
    const { t } = this.props;
    return (
      <div className="Comment">
        <div className="Comment__person-info">
          <img src={DefaultProfileImage} alt="Zdjęcie profilowe" />
          <div>
            <p><b>{this.#profileName}</b></p>
            <p className="text--light-gray">{this.#date[0] ? this.#date[0] : null} {t(this.#date[1])}</p>
          </div>
        </div>
        <p className="w100">{this.#comment}</p>
      </div>
    )
  }

  #dateDiff = (dateString) => {
    const timeDiff = (new Date().getTime()) - Date.parse(dateString);

    const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));
    const daysDiff = Math.floor(hoursDiff / 24);
    const monthsDiff = Math.floor(daysDiff / 30.44);
    const yearsDiff = Math.floor(monthsDiff / 12);
    // console.log(timeDiff, hoursDiff, daysDiff, monthsDiff, yearsDiff);
    
    if (hoursDiff === 0 ) return [0, "lessThanHour"]
    else if (hoursDiff < 24) return [hoursDiff, "hours"]
    else if (daysDiff === 1) return [1, "day"]
    else if (daysDiff < 31) return [daysDiff, "days"]
    else if (monthsDiff === 1) return [1, "month"]
    else if (monthsDiff < 12) return [monthsDiff, "months"]
    else if (yearsDiff === 1) return [1, "year"]
    else return [yearsDiff, "years"]
  }
}

export default withTranslation()(Comment)