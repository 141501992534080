import React from 'react';
import { withTranslation } from 'react-i18next';
import './ExtensibleCard.sass';
import Arrow from './arrow.png';

class ExtensibleCard extends React.Component {
  #title;
  #content;
  #content2;
  #contentRef = React.createRef();
  #arrowRef = React.createRef();

  constructor(props) {
    super(props);
    this.#title = props.title;
    this.#content = props.content;
    this.#content2 = props.content2;
  }

  render () {
    const { t } = this.props;
    return (
      <div className="ExtensibleCard" onClick={this.#onExpandCollapse}>
        <div className="ExtensibleCard__title">
          <h4>{this.#title}</h4>
          <div className="ExtensibleCard__arrow" ref={this.#arrowRef}>
            <img src={Arrow} alt="v" />
          </div>
        </div>
        <div className="ExtensibleCard__content grid gap-20" ref={this.#contentRef}>
          <div>{this.#content}</div>
          <div>{this.props.children}</div>
          <div>{this.#content2}</div>
        </div>
      </div>
    )
  }

  #onExpandCollapse = (event) => {
    if (event.target.className === "ExtensibleCard__content ExtensibleCard__content--visible") return;
    this.#contentRef.current.classList.toggle("ExtensibleCard__content--visible");
    this.#arrowRef.current.classList.toggle("ExtensibleCard__arrow--rotated");
  }
}

export default withTranslation()(ExtensibleCard)