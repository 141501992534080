import React from "react";
import Logo from "./bluholding.webp";
import "./Header.sass";
import { withTranslation } from "react-i18next";
import LanguageSelector from "../LanguageSelector/LanguageSelector";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

class Header extends React.Component {
  #backgroundTransition;
  #headerRef = React.createRef();
  #menuRef = React.createRef();
  #svgRef = React.createRef();
  #mainPageCallback;

  constructor(props) {
    super(props);
    this.#mainPageCallback = props.mainPageCallback;
  }

	render() {
    const { t } = this.props;
    return (
      <header className="header">
        <div className="header__block">
          <a href="/">
            <img src={Logo} alt="BluHolding logo" />
          </a>
        </div>
        <div className="header__block--mobile" onClick={this.#onMobileMenu}>
          <svg width={40} height={32} className="svg" ref={this.#svgRef}>
            <rect className="svg__rect" width={40} height={5}></rect>
            <rect className="svg__rect" width={40} height={5}></rect>
            <rect className="svg__rect" width={40} height={5}></rect>
          </svg>
        </div>
        <div className="header__block header__options" ref={this.#menuRef}>
          <HashLink to="/#o-nas" className="header__option">{t("aboutUs")}</HashLink>
          {
            
            this.#mainPageCallback ? <a className="header__option--fav" onClick={() => this.#mainPageCallback("#opis-projektu")}>{t("projectDesc")}</a> : <HashLink to="/#opis-projektu" className="header__option">{t("projectDesc")}</HashLink>
          }
          <HashLink to="/#nasze-marki" className="header__option">{t("ourBrands")}</HashLink> 
          <HashLink to="/#zaufali-nam" className="header__option">{t("trustedUs")}</HashLink>
          <HashLink to="/#partnerzy" className="header__option">{t("partners")}</HashLink> 
          <Link to="/nasza-historia" className="header__option">{t("ourHistory")}</Link> 
          <HashLink to="/#wyroznienia" className="header__option">{t("awards")}</HashLink> 
          <HashLink to="/#referencje" className="header__option">{t("recommendations")}</HashLink> 
          <HashLink to="/#csr" className="header__option">{t("csr")}</HashLink> 
          {/* <Link to="/voucher" className="header__option">{t("voucher")}</Link>  */}
          <a href="#kontakt" className="header__option">{t("contact")}</a>
          <span>&nbsp;</span>
          <span className="header__option">
            <LanguageSelector />
          </span>
        </div>
      </header>
    );
  }

  #onMobileMenu = () => {
    const menu = this.#menuRef.current;
    menu.classList.toggle("header__options--visible");
    const button = this.#svgRef.current;
    button.classList.toggle("svg");
    button.classList.toggle("svg--active");
  };
}

export default withTranslation()(Header);