import { t } from 'i18next';
import React from 'react';
import { withTranslation } from 'react-i18next';
import Investment from '../../components/Investment/Investment';

class InvestList extends React.Component {
  // Lista inwestycji posortowana malejąco i podzielona na takie same wartości, indeks 0 to inwestorzy strategiczni, 1 i 2 to mecenasi, 3 i dalej to crowdfunderzy
  #investList = [
    [
      {
        name: "",
        value: 127500,
        date: "2023-01-23"
      },
      {
        name: "Maria B.",
        value: 127500,
        date: "2023-01-23"
      },
      {
        name: "Justyna B.",
        value: 127500,
        date: "2023-01-23"
      }
    ],
    [
      {
        name: "",
        value: 51000,
        date: "2023-01-23"
      },
      {
        name: "Maria B.",
        value: 51000,
        date: "2023-01-23"
      },
      {
        name: "Justyna B.",
        value: 51000,
        date: "2023-01-23"
      }
    ],
    [
      {
        name: "ktoś",
        value: 25500,
        date: "2023-01-23"
      },
      {
        name: "xxx",
        value: 25500,
        date: "2023-01-23"
      },
      {
        name: "yyy",
        value: 25500,
        date: "2023-01-23"
      }
    ],
    [
      {
        name: "Kamil B.",
        value: 5100,
        date: "2023-01-23"
      },
      {
        name: "Kamil D.",
        value: 5100,
        date: "2023-01-23"
      },
      {
        name: "Marek T.",
        value: 5100,
        date: "2023-01-23"
      }
    ],
    [
      {
        name: "Kamil B.",
        value: 510,
        date: "2023-01-23"
      },
      {
        name: "Jacek A.",
        value: 510,
        date: "2023-01-23"
      },
      {
        name: "Adam O.",
        value: 510,
        date: "2023-01-23"
      }
    ]
    
  ]
  render () {
    const { t } = this.props
    return (
      <div>
        <div>
          <h3>{t("investList")}</h3>
        </div>
        <div className="grid--custom-Invest2">
          {this.#investList.map((elem, colIndex) => {
            return (
              <>
              <h4 id={`heading-item-${colIndex}`} className={`invest__column-name${this.#titleBasedOnIndex(colIndex) === " " ? "--empty" : ""}`}>{this.#titleBasedOnIndex(colIndex)}</h4>
              <div className={`grid gap-20 item-${colIndex+1}`}>
                {
                  elem.map((investment, index) => {
                    return (
                      <Investment
                        name={t("infoSoon")}
                        /*value={`${(investment.value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} PLN`}*/
                        date={investment.date}
                        key={index}
                        icon={this.#iconLink(colIndex)}
                      />
                    )
                  })
                }
              </div>
              </>
            )
          })}
        </div>
      </div>
    )
  }

  #titleBasedOnIndex = (index) => {
    if (index === 0) return t("strategicInvestor");
    else if (index === 1) return t("patron");
    else if (index === 3) return t("crowdfounder");
    else return " ";
  }

  #iconLink = (index) => {
    if (index === 0) return "/static/icons/invest/strategic.svg";
    else if (index === 1 || index === 2) return "/static/icons/invest/patron.png"
  }
}

export default withTranslation()(InvestList)