import React from 'react';
import Icon from './document.png';

class File extends React.Component {
  #fileLink;
  #fileName;
  #active;

  constructor (props) {
    super(props);
    this.#fileLink = props.fileLink;
    this.#fileName = props.fileName;
    this.#active = props.active;
  }

  render () {
    return (
      <a href={this.#active ? this.#fileLink : "javascript:void(0)"} target={this.#active ? "_blank" : "_self"}>
        <div className={this.#active ? "File" : "File__inactive"}>
          <img width={65} height={65} src={Icon} alt="dokument" />
          <p>{this.#fileName}</p>
        </div>
      </a>
    )
  }
}

export default File